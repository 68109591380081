<template>
  <section class="home">
    <section class="" v-if="events">
      <!-- <v-row justify="center" align="center" class="px-0">
        <v-col cols="12" sm="12">
          <v-img src="/img/reg_banner.png" alt="" srcset=""></v-img>
        </v-col> 
      </v-row>-->
      <v-row class="justify-center px-4 mt-6">
        <v-col cols="12" xs="12" sm="6" md="4">
          <v-card :loading="loading" class="">
            <template slot="progress">
              <v-progress-linear
                color="deep-purple"
                height="10"
                indeterminate
              ></v-progress-linear>
            </template>
            <v-img
              class="white--text align-end"
              height="250"
              :src="eventDetails.thumbUrl"
            >
            </v-img>
            <v-card-text>
              <v-card class="mx-auto" flat>
                <v-list-item three-line v-if="eventDetails">
                  <v-list-item-content>
                    <v-list-item-title class="headline mb-3 font-weight-bold">
                      {{ eventDetails.title }}
                    </v-list-item-title>
                    <div class="subtitle-1 mt-0">{{ eventDetails.days }}</div>
                    <v-list-item-subtitle>{{
                      eventDetails.subtitle
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-card-actions> </v-card-actions>
                <v-divider class="mx-4 mt-0"></v-divider>
                <v-card-text>
                  <div>
                    {{ eventDetails.description }}
                  </div>
                </v-card-text>
              </v-card>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" xs="12" sm="6" md="5">
          <v-card class="px-6">
            <v-card-title class="headline font-weight-bold"
              >REGISTER HERE</v-card-title
            >
            <v-card-actions> </v-card-actions>
            <v-divider class="mx-4 mt-2"></v-divider>
            <v-card-text v-if="eventDetails.selected">
              <v-form
                ref="regForm"
                v-model="valid"
                @submit.prevent="submit"
                action
              >
                <v-row dense>
                  <v-col cols="12" sm="12" md="6">
                    <div class>
                      <div class="txt-titles">Title</div>
                      <!-- <div style="width: 100px"> -->
                      <v-text-field
                        class="text-field"
                        v-model="userModel.title"
                        :rules="nameRules"
                        :disabled="loading"
                        validate-on-blur
                        outlined
                        name="title"
                      ></v-text-field>
                      <!-- </div> -->
                    </div>
                  </v-col>
                  <v-col cols="12" sm="12" md="6">
                    <div class="">
                      <div class="txt-titles">Firstname</div>
                      <v-text-field
                        class="text-field"
                        v-model="userModel.firstname"
                        :rules="nameRules"
                        :disabled="loading"
                        validate-on-blur
                        outlined
                        name="firstname"
                      ></v-text-field>
                    </div>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col cols="12" sm="12" md="6">
                    <div class>
                      <div class="txt-titles">Lastname</div>
                      <v-text-field
                        class="text-field"
                        v-model="userModel.lastname"
                        :rules="nameRules"
                        :disabled="loading"
                        validate-on-blur
                        outlined
                        name="lastname"
                      ></v-text-field>
                    </div>
                  </v-col>
                  <v-col cols="12" sm="12" md="6">
                    <div class>
                      <div class="txt-titles">Gender</div>
                      <v-select
                        class="text-field"
                        :items="gender"
                        v-model="userModel.gender"
                        placeholder="Select gender"
                        :rules="nameRules"
                        :disabled="loading"
                        validate-on-blur
                        outlined
                        name="gender"
                      ></v-select>
                    </div>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col cols="12" sm="12" md="6">
                    <div>
                      <div class="txt-titles">Phone</div>
                      <v-text-field
                        class="text-field"
                        v-model="userModel.phone"
                        :rules="nameRules"
                        :disabled="loading"
                        validate-on-blur
                        outlined
                        name="phone"
                      ></v-text-field>
                    </div>
                  </v-col>
                  <v-col cols="12" sm="12" md="6">
                    <div>
                      <div class="txt-titles">Email Address</div>
                      <v-text-field
                        class="text-field"
                        v-model="userModel.email"
                        :rules="emailRules"
                        :disabled="loading"
                        validate-on-blur
                        outlined
                        name="email"
                        type="email"
                      ></v-text-field>
                    </div>
                  </v-col>
                </v-row>

                <v-row dense>
                  <v-col cols="12" sm="12" md="6">
                    <div>
                      <div class="txt-titles">Denomination</div>
                      <v-text-field
                        class="text-field"
                        v-model="userModel.denomination"
                        :rules="nameRules"
                        :disabled="loading"
                        validate-on-blur
                        outlined
                        name="denomination"
                      ></v-text-field>
                    </div>
                  </v-col>
                  <v-col cols="12" sm="12" md="6">
                    <div>
                      <div class="txt-titles">State</div>
                      <v-text-field
                        class="text-field"
                        v-model="userModel.location"
                        :rules="nameRules"
                        :disabled="loading"
                        validate-on-blur
                        outlined
                        name="location"
                      ></v-text-field>
                    </div>
                  </v-col>
                </v-row>
                <v-row class="justify-center">
                  <v-btn
                    rounded
                    class="px-10 my-4"
                    :loading="busy"
                    type="submit"
                    color="pink white--text px-6 ml-2"
                    x-large
                    >Submit</v-btn
                  >
                </v-row>
              </v-form>
            </v-card-text>
            <v-card-text v-else>
              <div>Event Registeration Closed</div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </section>
  </section>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  name: "Home",
  props: ["event"],
  methods: {
    ...mapActions(["showMessage", "httpPost"]),
    validate: function () {
      this.$refs.regForm.validate();
    },
    submit: function () {
      this.validate();
      this.busy = true;
      setTimeout(async () => {
        if (!this.valid) {
          this.showMessage({
            text: "Please fix issues with registration data",
            error: true,
          });
          this.busy = false;
        } else if (
          this.userModel.password_confirm !== this.userModel.password
        ) {
          this.showMessage({
            text: "Password and its confirmation don't match",
            error: true,
          });
          this.busy = false;
        } else {
          try {
            this.userModel.event = this.event;
            this.userModel.eventTitle = this.eventDetails.title;
            this.userModel.name = `${this.userModel.title} ${this.userModel.firstname} ${this.userModel.lastname}`;
            let resp = await this.httpPost({
              url: "/event_register",
              data: this.userModel,
              withAuth: false,
            });
            this.showMessage({
              text: "Your registeration has been accepted",
              error: false,
            });
            this.$refs.regForm.reset();
            // this.setUser(resp.data.user);
            console.info(resp);

            this.busy = false;
            this.$router.push({ name: "Success" });
          } catch (error) {
            this.busy = false;

            console.error("Error----", error.message);
            if (
              error.message.indexOf("network_error") != -1 ||
              error.message.toLowerCase().indexOf("invalid url") != -1
            ) {
              this.showMessage({
                text: "Could not connect to server please try again.",
                error: true,
              });
            } else {
              this.snackBarText = error.response.data.message;
              this.showMessage({
                text: error.response.data.message,
                error: true,
              });
            }
          }
        }
      }, 100);
    },
  },
  computed: {
    ...mapState(["isAuthenticated", "user", "loading", "events"]),
    eventDetails: function () {
      if (this.events) {
        if (
          this.events &&
          this.events.selected &&
          this.events.selected._id == this.event
        ) {
          return this.events.selected;
        } else {
          const ev = this.events.other.filter((x) => x._id == this.event);
          if (ev.length > 0) {
            return ev[0];
          } else {
            return {};
          }
        }
      } else {
        return {};
      }
    },
  },
  data() {
    return {
      gender: ["Male", "Female"],
      valid: false,
      acceptTerms: false,
      busy: false,
      userModel: {
        name: "",
        email: "",
      },
      nameRules: [(v) => !!v || "This Field is required"],
      emailRules: [
        (v) => !!v || "",
        (v) =>
          /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            v
          ) || "Valid email address required",
      ],
      model: 0,
      carousel_items: [
        {
          img: "c1",
        },
        {
          img: "c2",
        },
        {
          img: "c3",
        },
        {
          img: "c4",
        },
      ],
    };
  },
};
</script>

<style lang="css" scoped>
.hero {
  position: absolute;
  width: 100vw;
  top: 0;
  left: 0;
  z-index: 0;
}
.scrim {
  background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.9));
}

.help-item {
  width: 340px;
  height: 245px;
  background: #f2f2f2;
  border-radius: 33px;
  padding: 20px;
  margin-bottom: 30px;
}
.help-item-title {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 45px;
  color: #000000;
}
.text-field {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
}
.panel-title {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  color: #555;
  text-align: center;
}
.help-item-text {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 27px;
  color: #000000;
}
.txt-titles {
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 36px;
  padding-bottom: 5px;
}

@media screen and (max-width: 500px) {
  .centerBg {
    top: 170px;
  }
}
@media screen and (max-width: 360px) {
  .centerBg {
    top: 140px;
  }
}
</style>